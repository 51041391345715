<script setup lang="ts">
const emit = defineEmits<{
  (e: 'click', opened: boolean): void
}>()

const { locales, locale } = useI18n()
const opened = ref(false)

function handleClose(open: boolean) {
  opened.value = open
  emit('click', opened.value)
}
</script>

<template>
  <div>
    <LanguageButtonIcon
      :is-active="opened"
      :locale="locale"
      @click="handleClose(true)"
    />
    <LazyLanguageModal
      v-if="opened"
      class="max-w-80"
      auto-focus
      :title="$t('components.common.language')"
      :locales="locales"
      :locale="locale"
      @click="handleClose(false)"
      @close="handleClose(false)"
    />
  </div>
</template>
